@tailwind base;
@tailwind components;
@tailwind utilities;

.active-nav-link {
	@apply bg-black bg-none text-white hover:!bg-black hover:!text-white;
}

.shimmer {
	@apply before:absolute before:inset-0 before:-translate-x-full before:animate-[shimmer_2s_infinite] before:bg-gradient-to-r before:from-transparent before:via-white/60 before:to-transparent;
}
