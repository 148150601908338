#iubenda-cs-banner {
	position: fixed;
	z-index: 9999;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #0b0b0b;
	background: #fff;

	padding: 28px 24px 28px 24px;
	font-weight: 300;
	line-height: 145%;
	font-size: 16px;

	/* Drop shadow */
	box-shadow: 0px 0px 25px 0px #0000000d;

	border-radius: 8px;
}

.iubenda-cs-brand {
	display: flex;
	align-items: center;
	justify-content: center;

	/*
	  This is a workaround to replace the Peerigon logo with a cookie illustration
	  while still showing the Peerigon logo in the Customization section
	*/
	background-image: url("/assets/cookie.svg");
	background-repeat: no-repeat;
	background-position: center;
	height: 120px;
}

.iubenda-cs-brand img {
	display: none;
}

.iubenda-cs-rationale {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

#iubenda-cs-paragraph {
	max-width: 472px;
}

/* Accept, Reject and Customize buttons */
.iubenda-cs-opt-group {
	@apply flex flex-row items-center justify-between gap-4;
}

/* Accept and Reject buttons */
.iubenda-cs-opt-group-consent {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 8px;
}

/* Customize button */
.iubenda-cs-opt-group-custom {
	text-align: center;
}

button.iubenda-cs-btn-primary {
	@apply rounded-full border bg-gray-nav px-2 py-1 text-black transition-colors;
}

button.iubenda-cs-btn-primary:hover {
	background: #000;
	color: #fff;
}

button.iubenda-cs-btn-primary:focus {
	border: 1px solid #000;
}

.iubenda-cs-customize-btn,
#iubenda-cs-paragraph a {
	color: #000;
	text-decoration: underline;
	cursor: pointer;
	font-weight: 500;
}

.iubenda-privacy-policy-link {
	text-decoration: none;
}

button.iubenda-cs-preferences-link {
	display: none !important;
}

.iub__us-widget {
	display: none;
}

.iubenda-cs-uspr-link {
	display: none;
}

.iubenda-cs-preferences-link {
	@apply cursor-pointer whitespace-nowrap text-base font-light leading-larger disable-anchor-shadow-styles hover:underline focus:underline;
}

#purposes-container .purposes-header {
	background-color: #fff !important;
}
